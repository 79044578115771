/**
 * Diffent Connection Configs */

import CONFIG from "../../app.config";

export const BACKEND_BASE_URL = CONFIG.backendBaseUrl;
export const IMAGE_BASE_URL = CONFIG.image
// export const BACKEND_BASE_URL = 'https://bk-erp.plutonic.co.in/';
// export const IMAGE_BASE_URL = '/media';

// export const IMAGE_BASE_URL = 'https://bk-erp.plutonic.co.in/media';
export const RAZORPAY_KEY = 'rzp_live_Wl01N16ABswm3p';
